.hero {
  width: 100vw;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover; /* Adjust as needed to handle image size */
  background-position: center center; /* Adjust as needed to position the image */
  background-repeat: no-repeat;
}

.hero-img {
  width: 55vw;
  height: 100%;
  object-fit: cover;
  background-image: url('../../assets/bg1.jpg');
  background-size: cover;
  background-color: #0000001b;
  background-blend-mode: multiply;
  border-left: 2px solid #cac3ab;
}

.vip-container {
  height: 25vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
}

.vip-text-container {
  width: calc(45vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 2px solid #cac3ab;
  align-items: center;
  border-right: 2px solid #cac3ab;
}

.vip-text {
  font-weight: 700;
  color: #303030;
  padding: 30px !important;
  text-align: left;
}

.vip-desc {
  text-align: center;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.vip-action-container {
  width: 55vw;
  display: flex;
  border-top: 2px solid #cac3ab;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vip-container {
  width: 100vw;
  display: flex;
  justify-content: space-between;
}

.header {
  position: absolute;
}

.header-action-container {
  border-right: 2px solid #cac3ab;
  display: flex;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  align-items: center;
  justify-content: space-around;
  border-bottom: 2px solid #cac3ab;
}

.logo {
  font-family: 'Pacifico', cursive;
  font-size: 30px;
  padding: 1px !important;
  background: linear-gradient(to right, #6B1EE9, #854be1, #b692ef);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.header-action {
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
}

.contact-btn {
  width: auto;
  position: absolute;
  height: 35px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  top: 20px;
  color: rgb(230, 230, 230);
  box-shadow: none;
  font-size: 11px;
  border-radius: 0px;
  background-color: transparent;
  border: 1px solid rgb(230, 230, 230);
  right: 40px;
  transition: all 0.15s ease-in-out;
}

.contact-btn:hover {
  background-color: #ffffff23;
}

.contact-btn:active {
  transform: scale(0.95);
}