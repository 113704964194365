body {
  font-family: Arial, sans-serif;
  background-color: #F2F5FA;
  color: #333;
  margin: 0;
  padding: 20px;
}

h1 {
  color: #236EFF;
  text-align: center;
}

p {
  line-height: 1.5;
}

.container {
  /* max-width: 800px; */
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-info {
  margin-top: 20px;
  font-weight: bold;
  text-align: center;
}