.hero-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-title {
  font-weight: 600;
  color: #303030;
  font-size: 40px;
  padding-left: 10px !important;
  padding-right: 10px !important;

  text-align: center;
}

.hero-title b {
  font-weight: 300;
  color: #6b1ee9;
  font-family: "Pacifico", cursive;
}

.hero-subtitle {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
  font-weight: 600;
  color: #4d445b;
  font-size: 16px;
  text-align: center;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.user-download-container {
  width: 60vw;
  margin-left: 100px !important;
  position: relative;
  display: flex;
  padding-left: 10px !important;
  padding-right: 10px !important;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  background-color: white;
  box-shadow: 4px 4px 15px rgba(90, 90, 90, 0.136);
}

.user-download-container a {
  text-decoration: none;
}

.user-download-btn {
  box-shadow: none;
  width: auto;
  display: inline;
  background-color: #6b1ee9;
  border-radius: 0px;
  font-weight: 800;
  padding: 20px !important;
  transition: all 0.15s ease-in-out;
}

.user-download-btn:hover {
  background-color: #5118ae;
}

.user-download-btn:active {
  transform: scale(0.95);
}

.vertical-line {
  width: 1px;
  height: 35px;
  margin-left: 10px !important;
  margin-right: 10px !important;

  border-radius: 5px;
  background-color: rgb(212, 212, 212);
}

.info-text {
  text-align: center;
  background-color: white;
}

.info-text b {
  background-color: white;
}

.info-text .primary {
  color: #6b1ee9;
  font-size: small;
  display: block;
  margin-bottom: 10px !important;
}

.info-text .secondary {
  color: #4d445b;
  display: block;
  font-size: 14px;
}
