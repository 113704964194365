.account-deletion {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  padding-left: 20px;
  padding-right: 20px;
}
h1 {
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type='tel'],
input[type='text'] {
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: none;
  background-color: #f8f8f8;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

button {
  display: block;
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.error-message {
  color: red;
}

.delete-button {
  background-color: #007bff;
}